dmx.Component('google-directions', {

    initialData: {
        routeIndex: 0,
        routes: [],
        status: ''
    },

    attributes: {
        map: { // required
            type: String,
            default: null
        },

        origin: { // required
            type: [Object, String],
            default: null
        },

        destination: { // required
            type: [Object, String],
            default: null
        },

        waypoints: {
            type: [Array, String], // array or | separated string
            default: null
        },

        'travel-mode': {
            type: String,
            default: 'DRIVING' // DRIVING, WALKING, BICYCLING, TRANSIT
        },

        'unit-system': {
            type: String,
            default: 'METRIC' // METRIC, IMPERIAL
        },

        'departure-time': {
            type: Date,
            default: null
        },

        'arrival-time': {
            type: Date,
            default: null
        },

        'transit-modes': {
            type: Array,
            default: null // BUS, RAIL, SUBWAY, TRAIN, TRAM
        },

        'transit-preference': {
            type: String,
            default: null // FEWER_TRANSFERS, LESS_WALKING
        },

        'show-directions': {
            type: Boolean,
            default: false
        },

        'provide-alternatives': {
            type: Boolean,
            default: false
        },

        'avoid-ferries': {
            type: Boolean,
            default: false
        },

        'avoid-highways': {
            type: Boolean,
            default: false
        },

        'avoid-tolls': {
            type: Boolean,
            default: false
        },

        'optimize-waypoints': {
            type: Boolean,
            default: false
        },

        draggable: {
            type: Boolean,
            default: false
        },

        'dynamic-update': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        route: function() {
            this.route();
        },

        setRouteIndex: function(index) {
            if (this.directions) {
                this.set('routeIndex', index);
                this.renderer.setRouteIndex(index);
            }
        }
    },

    events: {
        directionschanged: Event,
        routeindexchanged: Event,
        noresults: Event,
        success: Event,
        error: Event
    },

    render: function(node) {
        this.target = document.getElementById(this.props.map);
        this.map = this.target && this.target.dmxComponent && this.target.dmxComponent.map;

        this.service = new google.maps.DirectionsService();
        this.renderer = new google.maps.DirectionsRenderer({
            draggable: this.props.draggable
        });

        this.renderer.addListener('directions_changed', this.directionsChanged.bind(this));
        this.renderer.addListener('routeindex_changed', this.routeindexChanged.bind(this));

        this.route();
    },

    update: function(props) {
        if (this.props.map && !this.map) {
            this.map = this.target && this.target.dmxComponent && this.target.dmxComponent.map;
        }

        if (this.props.draggable != props.draggable) {
            this.renderer.setOptions({ draggable: this.props.draggable });
        }

        if (this.props['dynamic-update']) {
            if (
                JSON.stringify(this.props.origin) != JSON.stringify(props.origin) ||
                JSON.stringify(this.props.destination) != JSON.stringify(props.destination) ||
                JSON.stringify(this.props.waypoints) != JSON.stringify(props.waypoints)
            ) {
                this.route();
            }
        }
    },

    route: function() {
        if (!this.props.origin || !this.props.destination) return;

        var origin = this.props.origin;
        var destination = this.props.destination;

        if (Array.isArray(origin)) {
            origin = origin[0];
        }

        if (Array.isArray(destination)) {
            destination = destination[0];
        }

        if (this.props.origin.latitude && this.props.origin.longitude) {
          origin = { lat: +this.props.origin.latitude, lng: +this.props.origin.longitude };
        } else if (origin.lat && origin.lng) {
            // make sure it are numbers
            origin.lat = +origin.lat;
            origin.lng = +origin.lng;
        }

        if (this.props.destination.latitude && this.props.destination.longitude) {
          destination = { lat: +this.props.destination.latitude, lng: +this.props.destination.longitude };
        } else if (destination.lat && destination.lng) {
            // make sure it are numbers
            destination.lat = +destination.lat;
            destination.lng = +destination.lng;
        }

        var request = {
            origin: origin,
            destination: destination,
            waypoints: this.getWaypoints(),
            optimizeWaypoints: this.props['optimize-waypoints'],
            travelMode: google.maps.TravelMode[this.props['travel-mode']],
            unitSystem: google.maps.UnitSystem[this.props['unit-system']],
            provideRouteAlternatives: this.props['provide-alternatives'],
            avoidFerries: this.props['avoid-ferries'],
            avoidHighways: this.props['avoid-highways'],
            avoidTolls: this.props['avoid-tolls']
        };

        if (this.props['travel-mode'] == 'TRANSIT') {
            request.transitOptions = {};

            if (this.props['arrival-time']) {
                request.transitOptions.arrivalTime = new Date(this.props['arrival-time']);
            }

            if (this.props['departure-time']) {
                request.transitOptions.departureTime = new Date(this.props['departure-time']);
            }

            if (Array.isArray(this.props['transit-modes'])) {
                request.transitOptions.modes = this.props['transit-modes'];
            }

            if (this.props['transit-preference']) {
                request.transitOptions.routingPreference = this.props['transit-preference'];
            }
        }

        if (this.props['travel-mode'] == 'DRIVING' && this.props['departure-time']) {
            request.drivingOptions = {};

            if (this.props['departure-time']) {
                request.drivingOptions.departureTime = new Date(this.props['departure-time']);
            }
        }

        this.service.route(request, this.updateRoute.bind(this)).catch(function(error) {
            // do nothing, just preventing uncought error
        });
    },

    getWaypoints: function() {
        if (typeof this.props.waypoints == 'string') {
            this.props.waypoints = this.props.waypoints.split(/\s*\|\s*/);
        }

        if (Array.isArray(this.props.waypoints)) {
            return this.props.waypoints.map(function(waypoint) {
                if (waypoint.lat) waypoint.lat = +waypoint.lat;
                if (waypoint.lng) waypoint.lng = +waypoint.lng;
                return { location: waypoint };
            });
        }

        return null;
    },

    routeindexChanged: function() {
        this.set('routeIndex', this.renderer.getRouteIndex());
        requestAnimationFrame(this.dispatchEvent.bind(this, 'routeindexchanged'));
    },

    directionsChanged: function() {
        var directions = this.renderer.getDirections();

        this.set('routes', directions.routes.map(function(route, index) {
            return {
                index: index,
                copyrights: route.copyrights,
                summary: route.summary,
                fare: route.fare ? {
                    currency: route.fare.currency,
                    value: route.face.value
                } : null,
                totalMeters: route.legs.reduce(function(d, leg) {
                    d += (leg.distance && leg.distance.value || 0);
                    return d;
                }, 0),
                totalSeconds: route.legs.reduce(function(d, leg) {
                    d += (leg.duration && leg.duration.value || 0);
                    return d;
                }, 0),
                waypointsOrder: route.waypoint_order,
                legs: route.legs.map(function(leg) {
                    return {
                        arrivalDate: leg.arrival_time && leg.arrival_time.value.toISOString(),
                        departureDate: leg.departure_time && leg.departure_time.value.toISOString(),
                        arrival: leg.arrival_time && leg.arrival_time.text,
                        departure: leg.departure_time && leg.departure_time.text,
                        distance: leg.distance.text,
                        duration: leg.duration.text,
                        meters: leg.distance.value,
                        seconds: leg.duration.value,
                        start: leg.start_address,
                        end: leg.end_address,
                        steps: leg.steps.map(function(step) {
                            var transit = {};

                            if (step.transit) {
                                transit.arrival = {
                                  stop: step.transit.arrival_stop.name,
                                  date: step.transit.arrival_time.value.toISOString(),
                                  time: step.transit.arrival_time.text
                                };
                                transit.departure = {
                                  stop: step.transit.departure_stop.name,
                                  date: step.transit.departure_time.value.toISOString(),
                                  time: step.transit.departure_time.text
                                };
                                transit.headsign = step.transit.headsign;
                                transit.numStops = step.transit.num_stops;
                                transit.line = step.transit.line;
                            }

                            return {
                                distance: step.distance.text,
                                duration: step.duration.text,
                                instructions: step.instructions,
                                maneuver: step.maneuver,
                                travelMode: step.travel_mode,
                                transit: transit
                            };
                        })
                    };
                })
            }
        }));

        setTimeout(this.dispatchEvent.bind(this, 'directionschanged'), 100);
    },

    updateRoute: function(directions, status) {
        this.set('routeIndex', 0);
        this.set('status', status);

        if (status == 'OK') {
            this.directions = directions;
            if (this.props.map && this.map) {
                this.renderer.setMap(this.map);
            } else {
                this.renderer.setMap(null)
            }
            this.renderer.setDirections(directions);

            if (this.props['show-directions']) {
                this.renderer.setPanel(this.$node);
            }

            requestAnimationFrame(this.dispatchEvent.bind(this, 'success'))
        } else {
            //console.warn(directions, status);
            this.directions = null;
            this.set('routes', []);
            if (status == 'ZERO_RESULTS') {
                requestAnimationFrame(this.dispatchEvent.bind(this, 'noresults'));
            } else {
                requestAnimationFrame(this.dispatchEvent.bind(this, 'error'));
            }
        }
    }

});
